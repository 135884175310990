.auth-signin {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  font-family: Helvetica, Arial, sans-serif;
  color: #505050;
  z-index: 0;
}
.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: var(--size-head2);
  font-weight: 700;
  border-radius: 0.25rem;
}
.button:hover {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button-primary {
  color: var(--color-white);
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.button-sm {
  padding: 4px 45px;

  font-size: var(--size-global2);
  border-radius: 0.2rem;
}
.log-box {
  display: block;
  max-width: 400px;
  margin: 60px auto 20px;
  background-color: var(--color-white);
  box-shadow: 5px 5px 10px var(--color-border);
  border: solid 1px#E7E7E7;
  padding: 80px 20px 20px 20px;
}

.auth-signin-logo {
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  margin: 0 -60px;
  height: 120px;
  width: 120px;
  background-color: var(--color-white);
  border: solid 1px#E7E7E7;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../../../images//logo.svg");
  border-radius: 60px;
}

.text {
  display: block;
  font-size: var(--size-head6);
  text-decoration: none;
  color: #505050;
  cursor: pointer;
}
